import * as React from "react"
import {Link } from 'gatsby'
import Layout from '../components/layout'
import { Container, Row, Col} from "react-bootstrap"
import Seo from "../components/seo"
import { Helmet } from "react-helmet";
import { navigate } from "gatsby"
import axios from "axios"

const getPropurl = async (handler, crmid) => {
	let result = []
	//instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
	var data = JSON.stringify({
		"query": "query($crm_id:[String]) { properties(where: { crm_id: $crm_id, publish: true}) { id, slug, search_type, department } }",
		"variables": {"crm_id": [crmid] }
	  });
	var config = {
		method: 'post',
		url: `${process.env.GATSBY_STRAPI_SRC}graphql`,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
		},
		data : data
	};
	const res = await axios(config);
	result[0] = JSON.stringify(res.data);
	handler(result);//update state var
}
const NotFoundPage = (props) => {
	const [showtemplate, setShowtemplate] = React.useState(false);
	const [propdata, setPropdata] = React.useState('');
	React.useEffect(() => {
		//we doing here as fallback, if any case build failed or not triggered then the below will be useful; when build happens redirects will happen a t netlify itself, so dont need to do CSR
		let regex = "\[a-zA-Z]{3}[0-9]{6}"; //you can modify the preg as per proj feed
		let regex2 = "\[a-zA-Z]{2}[0-9]{4}"; //you can modify the preg as per proj feed
		if(props?.location?.pathname){
			let crm_id="";
			if(props?.location?.pathname?.length == 10){
				crm_id = (props?.location?.pathname).match(regex);
			} else if(props?.location?.pathname?.length == 7){
				crm_id = (props?.location?.pathname).match(regex2);
			}
			//mke sure state value is empty and we have proper url to proceed
			if(typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata){
				getPropurl(setPropdata, crm_id[0]); // call the function to fetch data from strapi
			} else if(propdata){
				let property_data = JSON.parse(propdata);
				let property_arr = property_data.data.properties;
				if(property_arr.length > 0) {
					let property = property_arr[0];
					//set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
					if(property.department == 'residential' || property.department == 'long_term' || property.department == 'short_term') {
						if(property.id) {
						  if (property.search_type == 'sales') {
							navigate(`/property-for-sale/${property.slug}-${property.id}`,{ replace: true });
						  }
						  else if (property.search_type == 'lettings') {
							navigate(`/property-to-rent/${property.slug}-${property.id}`,{ replace: true });
						  }
						}
					}
				} else {
					setShowtemplate(true);// show 404 page template
				}
			} else {
				setShowtemplate(true);// show 404 page template
			}
		}
	  },[propdata]);

    return (
      <div>
      {showtemplate &&
      <div>
        <Layout layout="content_only_template">   
        <Helmet
        bodyAttributes={{
          class: `templates-sitemap-template`,
        }}
        />
        <Seo title="404: Not found" />
          <div className="page-top page-404">
            <div className="basic-layout">
              <Container>
                <Row>
                  <Col>
                  <h1>404: Not Found</h1>
                      <div className="page-404-para">
                        <p>This maybe because it has been removed, we’ve changed its name or it is temporarily unavailable.</p>
                        <div class="btn-blk d-md-flex d-block d-lg-flex">
                          <Link to="/" className="outline btn">
                            Back to Home
                          </Link>
                          <Link to="/contact-crisp-cowley-estate-agents/" className="outline btn">
                          contact us
                          </Link>
                        </div> 
                      </div>
                  </Col>
                </Row>
              </Container>  
            </div>  
          </div>
        </Layout>
      </div> }
      </div>)
}
export default NotFoundPage